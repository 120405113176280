import React from "react";
import HeroSection from "../../common/HeroSection/HeroSection";
import Header from "../../common/Header/Header";
import InfoCards from "../../main/InfoCard/InfoCards";
import About from "../../main/About/About";
import AppointmentForm from "../../main/AppointmentForm/AppointmentForm";
import TestimonialSlider from "../../main/Testimony/Testimony";
import Footer from "../../common/Footer/Footer";
import "./HomePage.css";
import Services from "../../main/Services/Services";

const HomePage = () => {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <InfoCards />
      <About />
      <TestimonialSlider />
      <Services />
      <AppointmentForm />
      <Footer />
    </div>
  );
};

export default HomePage;
