import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://lbtvbv4sqh.execute-api.ap-south-1.amazonaws.com/dev/v1',
});

apiClient.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default apiClient;
