import React from 'react';
import './InfoCards.css';

const InfoCards = () => {
    return (
        <section className="info-cards" id="consultation-hours">
            <div className="infro-cards-container">
                <div className="card doctor-timetable-card">
                    <h3>Consultation Hours</h3>
                    <p>Our doctors are available throughout the week. Please check the table below to find the most convenient time for your visit.</p>
                    <table className="timetable">
                        <thead>
                            <tr>
                                <th>Day</th>
                                <th>Working Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Monday</td>
                                <td>9:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Tuesday</td>
                                <td>9:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Wednesday</td>
                                <td>9:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Thursday</td>
                                <td>9:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Friday</td>
                                <td>9:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Saturday</td>
                                <td>9:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Sunday</td>
                                <td>9:00 AM - 8:00 PM</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="card location-card" id="consultation-location">
                    <h3>Find Us Here</h3>
                    <p>Visit us at our conveniently located facility. We are situated at the heart of Bengaluru, easily accessible and ready to serve you.</p>
                    <p><strong>Address:</strong> 4th floor, Patel Hanumakka complex, Hennur Bagalur Main Rd, above HDFC Bank, Kothanur, Bengaluru, Karnataka 560077</p>
                    <a href="https://www.google.com/maps/place/Dr+Holistic+Harmony+Acupuncture,Physiotherapy-Acupunture+near+me,Acupuncturist+Near+me,Physiotherapy+near+me,Best+Acupunture./@13.060975,77.6437201,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae172652cec1e5:0x92427efac6ab5f48!8m2!3d13.060975!4d77.648591!16s%2Fg%2F11txy0d2sv?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D" className="fancy-link" target="_blank" rel="noopener noreferrer">Get Directions</a>
                </div>
            </div>
        </section>
    );
}

export default InfoCards;
