import React, { useState, useEffect } from "react";
import "./AppointmentForm.css";
import Banner from "../../common/Banner/Banner";

const TimeSlotPopup = ({
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  onClose,
}) => {
  const timeSlots = [
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
  ];

  return (
    <div className="time-slot-popup">
      <div className="popup-content">
        <h3>Select Appointment Date & Time</h3>
        <div>
          {" "}
          {"Date : "}
          <input
            type="date"
            className="date-selector"
            placeholder="Appointment Date"
            name="AppointmentDate"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
        </div>
        <div className="time-slots">
          {timeSlots.map((slot) => (
            <button
              key={slot}
              className={`time-slot ${selectedTime === slot ? "selected" : ""}`}
              onClick={() => setSelectedTime(slot)}
            >
              {slot}
            </button>
          ))}
        </div>
        <div className="popup-actions">
          <button className="popup-actions__close" onClick={onClose}>
            Close
          </button>
          <button
            className="popup-actions__confirm"
            onClick={onClose}
            disabled={!selectedDate || !selectedTime}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const AppointmentForm = () => {
  const [appointmentData, setAppointmentData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    appointmentDate: "",
    appointmentTime: "",
    message: "",
  });

  const [yogaData, setYogaData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    notes: "",
  });

  const [banner, setBanner] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const [isAppointmentFormValid, setIsAppointmentFormValid] = useState(false);
  const [isYogaFormValid, setIsYogaFormValid] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingYoga, setIsLoadingYoga] = useState(false);

  const handleAppointmentChange = (e) => {
    setAppointmentData({
      ...appointmentData,
      [e.target.name]: e.target.value,
    });
  };

  const handleYogaChange = (e) => {
    setYogaData({
      ...yogaData,
      [e.target.name]: e.target.value,
    });
  };

  const showBanner = (message, type) => {
    setBanner({
      message,
      type,
      visible: true,
    });
    setTimeout(() => setBanner({ ...banner, visible: false }), 5000);
  };

  const handleAppointmentSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://p3a52kf6gk.execute-api.ap-south-1.amazonaws.com/dev/v1/appointments",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...appointmentData,
            appointmentDate: `${selectedDate} ${selectedTime}`,
          }),
        }
      );
      if (response.ok) {
        setIsLoading(false);
        showBanner("Appointment booked successfully!", "success");
        setAppointmentData({
          name: "",
          phoneNumber: "",
          email: "",
          appointmentDate: "",
          message: "",
        });
        setSelectedDate("");
        setSelectedTime("");
      } else {
        setIsLoading(false);
        showBanner("Failed to book appointment.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showBanner("An error occurred while booking your appointment.", "error");
    }
  };

  const handleYogaSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingYoga(true);
    try {
      const response = await fetch(
        "https://p3a52kf6gk.execute-api.ap-south-1.amazonaws.com/dev/v1/yoga-registrations",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(yogaData),
        }
      );
      if (response.ok) {
        setIsLoadingYoga(false);
        showBanner("Yoga class booked successfully!", "success");
        setYogaData({ name: "", phoneNumber: "", email: "", notes: "" });
      } else {
        setIsLoadingYoga(false);
        showBanner("Failed to book yoga class.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showBanner("An error occurred while booking your yoga class.", "error");
    }
  };

  useEffect(() => {
    const isValid =
      appointmentData.name &&
      appointmentData.phoneNumber.length === 10 &&
      selectedDate &&
      selectedTime;
    setIsAppointmentFormValid(isValid);
  }, [appointmentData, selectedDate, selectedTime]);

  useEffect(() => {
    const isValid = yogaData.name && yogaData.phoneNumber.length === 10;
    setIsYogaFormValid(isValid);
  }, [yogaData]);

  return (
    <section className="appointment" id="appointment">
      <div className="appointment-container">
        {banner.visible && (
          <Banner
            message={banner.message}
            type={banner.type}
            onClose={() => setBanner({ ...banner, visible: false })}
          />
        )}
        <div className="appointment-content">
          <div className="appointment-form normal-appointment">
            <h3>Just make an appointment and you are done</h3>
            <form onSubmit={handleAppointmentSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={appointmentData.name}
                  onChange={handleAppointmentChange}
                  placeholder="Full Name"
                  required
                />
                <input
                  type="tel"
                  name="phoneNumber"
                  value={appointmentData.phoneNumber}
                  onChange={handleAppointmentChange}
                  placeholder="Phone Number"
                  required
                  pattern="\d{10}"
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={appointmentData.email}
                  onChange={handleAppointmentChange}
                  placeholder="Email Address (optional)"
                />
              </div>
              <div className="form-group full-width">
                <input
                  type="text"
                  name="appointmentDate"
                  value={selectedDate ? `${selectedDate} ${selectedTime}` : ""}
                  placeholder="Select Appointment Date & Time"
                  readOnly
                  onClick={() => setIsPopupOpen(true)}
                  required
                />
              </div>
              <div className="form-group full-width">
                <textarea
                  name="message"
                  value={appointmentData.message}
                  onChange={handleAppointmentChange}
                  placeholder="Message (optional)"
                ></textarea>
              </div>
              <button
                className="appointment-submit-button"
                type="submit"
                disabled={!isAppointmentFormValid}
              >
                {isLoading && <span className="loading-spinner"></span>}
                {isLoading ? "Booking Appointment..." : "Book Appointment"}
              </button>
            </form>
          </div>
          <div className="appointment-form yoga-appointment">
            <h3>Try our yoga classes</h3>
            <form onSubmit={handleYogaSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={yogaData.name}
                  onChange={handleYogaChange}
                  placeholder="Full Name"
                  required
                />
                <input
                  type="tel"
                  name="phoneNumber"
                  value={yogaData.phoneNumber}
                  onChange={handleYogaChange}
                  placeholder="Phone Number"
                  required
                  pattern="\d{10}"
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={yogaData.email}
                  onChange={handleYogaChange}
                  placeholder="Email Address (optional)"
                />
              </div>
              <div className="form-group full-width">
                <textarea
                  name="notes"
                  value={yogaData.notes}
                  onChange={handleYogaChange}
                  placeholder="Specific Goals or Notes (optional)"
                ></textarea>
              </div>
              <button
                className="appointment-submit-button"
                type="submit"
                disabled={!isYogaFormValid}
              >
                {isLoadingYoga && <span className="loading-spinner"></span>}
                {isLoadingYoga ? "Booking yoga class..." : "Book Yoga Class"}
              </button>
            </form>
          </div>
        </div>
        {isPopupOpen && (
          <TimeSlotPopup
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            onClose={() => setIsPopupOpen(false)}
          />
        )}
      </div>
    </section>
  );
};

export default AppointmentForm;
