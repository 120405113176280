import React, { useState, useEffect } from "react";
import { Table, Button, Modal, message } from "antd";
import apiClient from "../../../common/LoginV2/axiosClient";
import GenerateBillModal from "./GenerateBillModal/GenerateBillModal";

const PatientRecordsAndBilling = () => {
  const [patientRecords, setPatientRecords] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedBillRecord, setSelectedBillRecord] = useState(null);
  const [selectedDetailsRecord, setSelectedDetailsRecord] = useState(null);
  const [isPatientDetailsVisible, setIsPatientDetailsVisible] = useState(false);
  const [isGenerateBillModalVisible, setIsGenerateBillModalVisible] =
    useState(false);
  const [isRecordDetailsModalVisible, setIsRecordDetailsModalVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPatientRecords();
  }, []);

  const fetchPatientRecords = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(
        "/patientTreatmentRecord/patients/records"
      );
      const records = Object.keys(response.data).map((patientId) => ({
        patientId,
        ...response.data[patientId],
      }));
      setPatientRecords(records);
    } catch (error) {
      message.error("Failed to fetch patient records");
    } finally {
      setLoading(false);
    }
  };

  const handleShowPatientRecordDetails = (patient) => {
    setSelectedPatient(patient);
    setIsPatientDetailsVisible(true);
  };

  const handleShowBillingDetails = (record) => {
    setSelectedDetailsRecord(record);
    setIsRecordDetailsModalVisible(true); // Open record billing details modal
  };

  const handleGenerateBill = (record) => {
    setSelectedBillRecord(record);
    setIsGenerateBillModalVisible(true);
  };

  const columns = [
    { title: "Patient Name", dataIndex: "patientName", key: "patientName" },
    { title: "Phone Number", dataIndex: "phoneNumber", key: "phoneNumber" },
    { title: "Pending Amount", dataIndex: "previousBalance", key: "previousBalance" },
    {
      title: "Actions",
      key: "actions",
      render: (_, patient) => (
        <Button
          onClick={() => handleShowPatientRecordDetails(patient)}
          type="link"
        >
          Show Details
        </Button>
      ),
    },
  ];

  const recordColumns = [
    {
      title: "Treatment Date",
      dataIndex: "treatmentDate",
      key: "treatmentDate",
    },
    {
      title: "Treatment Time",
      dataIndex: "treatmentTime",
      key: "treatmentTime",
    },
    {
      title: "Clinical Observation",
      dataIndex: "clinicalObservation",
      key: "clinicalObservation",
    },
    { title: "Prescription", dataIndex: "prescription", key: "prescription" },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button onClick={() => handleShowBillingDetails(record)} type="link">
            Show Details
          </Button>
          <Button
            onClick={() => handleGenerateBill(record)}
            type="link"
            style={{ marginLeft: 10 }}
          >
            Generate Bill
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="patient-record-management-container">
      <h2>Patient Record Management</h2>
      <Table
        columns={columns}
        dataSource={patientRecords}
        rowKey="patientId"
        loading={loading}
      />

      {/* Patient Record Details Modal */}
      <Modal
        title="Patient Records"
        visible={isPatientDetailsVisible}
        onCancel={() => setIsPatientDetailsVisible(false)}
        footer={null}
        width={1000}
      >
        {selectedPatient?.records ? (
          <div>
            <Table
              dataSource={Object.values(selectedPatient.records).map(
                (record) => ({
                  ...record.details, // Spread all properties of record.details
                  ...record.billing, // Spread all properties of record.billing
                })
              )}
              columns={recordColumns}
              rowKey="id"
              pagination={false}
            />
          </div>
        ) : (
          <p>No records available</p>
        )}
      </Modal>
      <Modal
        title="Billing Details"
        visible={isRecordDetailsModalVisible}
        onCancel={() => setIsRecordDetailsModalVisible(false)}
        footer={null}
        width={600}
      >
        {selectedDetailsRecord ? (
          <div>
            <p>
              <strong>Billing Date:</strong>{" "}
              {selectedDetailsRecord?.billingDate}
            </p>
            <p>
              <strong>Billing Time:</strong>{" "}
              {selectedDetailsRecord?.billingTime}
            </p>
            <div>
              <strong>Packages:</strong>
              <ul>
                {selectedDetailsRecord?.packages?.map((pkg, index) => (
                  <li key={index}>
                    <strong>Package Name:</strong> {pkg?.packageName},{" "}
                    <strong>Billing Amount:</strong> {pkg?.billingAmount}
                  </li>
                ))}
              </ul>
            </div>

            <p>
              <strong>Total Amount:</strong> {selectedDetailsRecord?.totalAmount}
            </p>
            <p>
              <strong>Paid Amount:</strong> {selectedDetailsRecord?.paidAmount}
            </p>
            <p>
              <strong>Total Remaining Amount:</strong>{" "}
              {selectedDetailsRecord?.totalRemainingAmount}
            </p>
            <p>
              <strong>Receipt Number:</strong>{" "}
              {selectedDetailsRecord?.receiptNumber}
            </p>
          </div>
        ) : (
          <p>No billing details available</p>
        )}
      </Modal>

      {/* Generate Bill Modal */}
      {selectedBillRecord && (
        <GenerateBillModal
          visible={isGenerateBillModalVisible}
          onClose={() => setIsGenerateBillModalVisible(false)}
          patientInfo={selectedPatient}
        />
      )}
    </div>
  );
};

export default PatientRecordsAndBilling;
