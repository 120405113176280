import React, { useEffect, useState } from "react";
import apiClient from "../../../common/LoginV2/axiosClient";
import {
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  Table,
  Popconfirm,
  notification,
} from "antd";
import {
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import "./AppointmentManagementPage.css";

const { Option } = Select;

const AppointmentManagementPage = () => {
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingPatient, setEditingPatient] = useState(null);
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isAppointmentModalVisible, setIsAppointmentModalVisible] =
    useState(false);
  const [isShowAppointmentsModalVisible, setIsShowAppointmentsModalVisible] = useState(false);
  const [appointmentForm] = Form.useForm();
  const [currentPatientId, setCurrentPatientId] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false);
  const [showButtonLoading, setShowButtonLoading] = useState(false);
  const [createAppointmentButtonLoading, setcreateAppointmentButtonLoading] = useState(false);


  // Fetch patients from API on initial load
  const fetchPatients = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(
        "/patientRegistration"
      );
      const { items } = response.data;
      const formattedItems = items.map((item) => ({
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        dob: item.dob,
        gmail: item.gmail,
        phoneNumber: item.phoneNumber,
        gender: item.gender,
        address: item.address,
      }));
      setPatients(formattedItems);
      setFilteredPatients(formattedItems);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
    finally {
      setLoading(false);
    }
  };

  // Fetch doctors and departments for appointment creation
  const fetchDoctorsAndDepartments = async () => {
    try {
      const doctorResponse = await apiClient.get(
        "/user"
      );
      const departmentResponse = await apiClient.get(
        "/department"
      );
      setDoctors(doctorResponse.data.items);
      setDepartments(departmentResponse.data.departments);
    } catch (error) {
      console.error("Error fetching doctors or departments:", error);
    }
  };

  useEffect(() => {
    fetchPatients();
    fetchDoctorsAndDepartments();
  }, []);

  useEffect(() => {
    const filtered = patients.filter(
      (patient) =>
        patient.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.phoneNumber.includes(searchTerm)
    );
    setFilteredPatients(filtered);
  }, [searchTerm, patients]);

  const handleAddPatient = () => {
    form.resetFields();
    setIsModalVisible(true);
    setIsEditing(false);
  };

  const handleEditPatient = (record) => {
    
    form.setFieldsValue({
      ...record,
      dob: moment(record.dob),
    });
    setIsModalVisible(true);
    setIsEditing(true);
    setEditingPatient(record);
  };

  const handleFormSubmit = async (values) => {
    setUpdateButtonLoading(true)
    const patientData = {
      firstName: String(values.firstName),
      lastName: String(values.lastName),
      dob: values.dob ? values.dob.format("YYYY-MM-DD") : "",
      gmail: String(values.gmail || ""),
      phoneNumber: String(values.phoneNumber),
      gender: String(values.gender),
      address: String(values.address || ""),
      ...(isEditing
        ? {}
        : {
            appointmentDate: values.appointmentDate
              ? values.appointmentDate.format("YYYY-MM-DD")
              : "",
            appointmentTime: values.appointmentTime
              ? values.appointmentTime.format("HH:mm")
              : "",
            departmentId: String("Ayurvedic"),
            doctorId: String(values.doctor || ""),
          }),
    };
    // console.log('editingPatient',editingPatient,buttonLoading)
    try {
      if (isEditing) {
        await apiClient.patch(
          `/patientRegistration/${editingPatient.id}`,
          patientData
        );
        setPatients(
          patients.map((item) =>
            item.id === editingPatient.id ? { ...item, ...patientData } : item
          )
        );
      } else {
        const response = await apiClient.post(
          "/patientRegistration",
          patientData
        );
        setPatients([...patients, { ...patientData, id: response.data._id }]);
      }
      setIsModalVisible(false);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving patient:", error);
    }
    finally{
      setUpdateButtonLoading(false);
    }
  };

  const handleCreateAppointment = (patientId) => {
    
    setCurrentPatientId(patientId);
    appointmentForm.resetFields();
    setIsAppointmentModalVisible(true);
  };

  const handleAppointmentSubmit = async (values) => {
    // setButtonLoading(true);
    setcreateAppointmentButtonLoading(true)
    const appointmentData = {
      patientId: currentPatientId,
      departmentId: values.departmentId,
      doctorId: values.doctorId,
      appointmentDate: values.appointmentDate.format("YYYY-MM-DD"),
      appointmentTime: values.appointmentTime.format("HH:mm"),
    };
    try {
      await apiClient.post(
        "/appointment",
        appointmentData
      );
      notification.success({ message: "Appointment created successfully!" });
      setIsAppointmentModalVisible(false);
    } catch (error) {
      console.error("Error creating appointment:", error);
      notification.error({ message: "Failed to create appointment!" });
    }
    finally {
      setcreateAppointmentButtonLoading(false);
    }
  };

  const handleShowAppointments = async (patientId) => {
    setCurrentPatientId(patientId);
    setShowButtonLoading(true);
    try {
      const response = await apiClient.get(
        `/appointment?patientId=${patientId}`
      );
      setAppointments(response.data.items);
      setIsShowAppointmentsModalVisible(true); // Open appointment viewing modal
    } catch (error) {
      console.error("Error fetching appointments:", error);
    } finally {
      setShowButtonLoading(false); // Hide the button loading indicator
    }
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="appointment-management-actions">
          <Button type="link" onClick={() => handleEditPatient(record)}>
            Edit
          </Button>
          <Button
            type="link"
            onClick={() => handleCreateAppointment(record.id)}
          >
            Create Appointment
          </Button>
          <Button type="link" onClick={() => handleShowAppointments(record.id)} loading={showButtonLoading}>
            Show Appointments
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="appointment-management-container">
      <div className="appointment-management-header">
        <h1>Patient Registration</h1>
        <Input
          placeholder="Search by Name or Phone Number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px", marginRight: "16px" }}
        />
        <Button type="primary" onClick={handleAddPatient}>
          Register Patient
        </Button>
      </div>
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
          <p>Loading patient records...</p>
        </div>
      ) :
      <Table columns={columns} dataSource={filteredPatients} rowKey="id" />}

      <Modal
        title={isEditing ? "Edit Patient" : "Register Patient"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: "Please enter the first name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: "Please enter the last name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="dob"
            label="Date of Birth"
            rules={[{ required: false, message: "Please enter date of birth" }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              { required: true, message: "Please enter phone number" },
              { len: 10, message: "Phone number must be 10 digits" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="gender"
            label="Gender"
            rules={[{ required: true, message: "Please select gender" }]}
          >
            <Select placeholder="Select Gender">
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item name="address" label="Address">
            <Input.TextArea />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={updateButtonLoading}>
            {isEditing ? "Update Patient" : "Register Patient"}
          </Button>
        </Form>
      </Modal>

      <Modal
        title="Create Appointment"
        visible={isAppointmentModalVisible}
        onCancel={() => setIsAppointmentModalVisible(false)}
        footer={null}
      >
        <Form form={appointmentForm} layout="vertical" onFinish={handleAppointmentSubmit}>
          <Form.Item
            name="doctorId"
            label="Doctor"
            rules={[{ required: true, message: "Please select a doctor" }]}
          >
            <Select placeholder="Select Doctor">
              {doctors.map((doctor) => (
                <Option key={doctor.id} value={doctor.id}>
                  {doctor.firstName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="departmentId"
            label="Department"
            rules={[{ required: true, message: "Please select a department" }]}
          >
            <Select placeholder="Select Department">
              {departments.map((department) => (
                <Option key={department} value={department}>
                  {department}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="appointmentDate"
            label="Date"
            rules={[{ required: true, message: "Please select appointment date" }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="appointmentTime"
            label="Time"
            rules={[{ required: true, message: "Please select appointment time" }]}
          >
            <DatePicker picker="time" style={{ width: "100%" }} />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={createAppointmentButtonLoading}>
            Create Appointment
          </Button>
        </Form>
      </Modal>

      <Modal
        title="Patient Appointments"
        visible={isShowAppointmentsModalVisible}
        onCancel={() => setIsShowAppointmentsModalVisible(false)}
        footer={null}
      >
        <Table
          dataSource={appointments}
          rowKey="id"
          columns={[
            {
              title: "Doctor",
              dataIndex: "doctorId",
              key: "doctorId",
              render: (doctorId) => {
                const doctor = doctors.find((doc) => doc.id === doctorId);
                return doctor ? doctor.firstName : "Unknown";
              },
            },
            {
              title: "Department",
              dataIndex: "departmentId",
              key: "departmentId",
            },
            {
              title: "Date",
              dataIndex: "appointmentDate",
              key: "appointmentDate",
              render: (date) => moment(date).format("YYYY-MM-DD"),
            },
            {
              title: "Time",
              dataIndex: "appointmentTime",
              key: "appointmentTime",
              render: (time) => moment(time, "HH:mm").format("hh:mm A"),
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default AppointmentManagementPage;
