import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import hospitalLogo from "../../images/hospitallogo.jpg";
import "./BillingPage.css";

function BillGenerator() {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    regDate: "",
    package: "",
    dueAmount: "",
    department: "",
    regEndDate: "",
    paidAmount: "",
    paymentDate: "",
    payments: [{ receiptNumber: "", type: "", amount: "" }],
    cashierName: "",
    cashierID: ""
  });

  const handleInputChange = (e, index = null) => {
    const { name, value } = e.target;
    if (index !== null) {
      const newPayments = [...formData.payments];
      newPayments[index][name] = value;
      setFormData({ ...formData, payments: newPayments });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const addPaymentField = () => {
    setFormData({
      ...formData,
      payments: [
        ...formData.payments,
        { receiptNumber: "", type: "", amount: "" },
      ],
    });
  };

  const calculateGrandTotal = () => {
    return formData.payments.reduce(
      (total, payment) => total + parseFloat(payment.amount || 0),
      0
    );
  };

  const totalWords = (num) => {
    // Convert number to words (for simplicity, this can be a simple function or a library)
    // Example function, you may replace it with a more accurate library.
    const ones = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousands = ["", "Thousand", "Million", "Billion"];

    if (num === 0) return "Zero";

    let word = "";
    let i = 0;

    while (num > 0) {
      if (num % 1000 !== 0) {
        word =
          (num % 100 < 20
            ? teens[num % 100 - 10] || ones[num % 10]
            : tens[Math.floor((num % 100) / 10)] + " " + ones[num % 10]) +
          " " +
          thousands[i] +
          " " +
          word;
      }
      num = Math.floor(num / 1000);
      i++;
    }

    return word.trim() + " Only";
  };

  const handleDownload = () => {
    const doc = new jsPDF();

    // Add your company logo (uncomment if needed)
    doc.addImage(hospitalLogo, "PNG", 0, 5, 70, 40);

    // Set font size and style for the address and phone number
    doc.setFont("helvetica", "bold");
    doc.setFontSize(9); // Reduce font size as needed

    // Define vertical spacing
    const lineHeight = 5;
    const numberHeight = 6; // Adjust line height to control the gap between lines

    // Add address lines
    doc.text("4th floor, Patel Hanumakka complex,", 140, 20);
    doc.text("Hennur Bagalur Main Rd, above HDFC ", 140, 20 + lineHeight); // Adjust y-coordinate for spacing
    doc.text("Bank, Kothanur, Bengaluru - 560077", 140, 20 + 2 * lineHeight); // Adjust y-coordinate for spacing

    // Add phone number
    doc.text("Phone Number: +91 91751 81049", 140, 20 + 3 * numberHeight); // Adjust y-coordinate for spacing

    // Receipt Section
    doc.setFontSize(18);
    doc.text("Receipt", 95, 40, { align: "center" });
    doc.setLineWidth(0.5);
    doc.line(10, 45, 200, 45);

    // Details Section
    doc.setFontSize(12);
    const startY = 55;
    const col1X = 10;
    const col2X = 125;

    doc.text(`Name: ${formData.name}`, col1X, startY);
    doc.text(`Department: ${formData.department}`, col2X, startY);
    doc.text(`Mobile Number: ${formData.mobile}`, col1X, startY + 10);
    doc.text(`Reg End Date: ${formData.regEndDate}`, col2X, startY + 10);
    doc.text(`Registration Date: ${formData.regDate}`, col1X, startY + 20);
    doc.text(`Paid Amount: ${formData.paidAmount}`, col2X, startY + 20);
    doc.text(`Package: ${formData.package}`, col1X, startY + 30);
    doc.text(`Payment Date: ${formData.paymentDate}`, col2X, startY + 30);
    doc.text(`Due Amount: ${formData.dueAmount}`, col1X, startY + 40);

    // Payment Table
    doc.autoTable({
      startY: startY + 50,
      head: [["S.No", "Receipt Number", "Payment Type", "Amount Paid"]],
      body: formData.payments.map((payment, index) => [
        index + 1,
        payment.receiptNumber,
        payment.type,
        payment.amount,
      ]),
    });

     // Add Grand Total below the table
     const grandTotal = calculateGrandTotal();
     const grandTotalY = doc.autoTable.previous.finalY + 10;
 
     doc.setFont("helvetica", "normal");
     doc.setFontSize(12);
     doc.text(`Grand Total: ${grandTotal}`, col2X, grandTotalY)

    // Total in Words
    // Assuming you have initialized your jsPDF instance as `doc`
    const grandTotalWords = totalWords(grandTotal);

    const wordsY = grandTotalY;

    doc.text("In words: ", col1X, wordsY);
    doc.setFont("helvetica", "normal");
    doc.text(grandTotalWords, col1X + doc.getTextWidth("In words: "), wordsY);

    // Terms and Conditions
    const termsY = doc.autoTable.previous.finalY + 20;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("Terms and Conditions", 105, termsY, { align: "center" });
    doc.setLineWidth(0.5);
    doc.line(10, termsY + 5, 200, termsY + 5); // Line below the heading
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const terms = [
      "•   The facilities of joining the card include any number of consultations with a physician",
      "    Only the bearer can avail the facilities of the card.",
      "•   The card facilities are given only to the one on whose name the card is made.",
      "•   The fee is non-transferable, non-refundable, and non-extendable.",
      "•   Patients are strictly advised to use medicines as per attending physician's recommendation.",
      "    We assume patients have the responsibility to inform the attending physician about the",
      "    status of their health or any serious disorder during the course of treatment.",
      "•   We expect and would appreciate patients to visit the clinic as per the due date of",
      "     their consultations.",
      "•   Patients are requested to cooperate with the mode of treatment, as sometimes, the speed",
      "    of recovery is slow (the time of recovery may vary).",
      "•   The duration of treatment and results may vary from patient to patient.",
      "•   The doctor and the clinic have given no guarantee to me (the patient) about the results and",
      "    duration of the treatment.",
      "•   During critical emergencies, patients/attendants are advised to inform the attending physician.",
      "•   Case sheet records are (digital) and kept with the doctor (in server) till the end of the",
      "    course of the treatment.",
      "•   This corporate clinic promises to provide the best service and treatment to all patients.",
    ];
    doc.text(terms, 10, termsY + 15);

    // Signature Section
    const signatureY = termsY + 110;
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Patient / Attendants Signature: ____________________", 10, signatureY);
    doc.text(`Mobile Number: ${formData.mobile}`, 10, signatureY + 7);
    doc.text("Authorized Signatory: _________________", 120, signatureY);
    doc.text(`Cashier Name: ${formData.cashierName}`, 120, signatureY + 7);
    doc.text(`Cashier ID: ${formData.cashierID}`, 120, signatureY + 14);

    doc.line(10, signatureY + 20, 200, signatureY + 20); // Line below signatures

    // Footer
    const footerY = signatureY + 30;
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("Bangalore, Karnataka, India", 55, footerY, {
      align: "center",
    });
    doc.text("Email: drholisticharmony.com, Mobile: +91 91751 81049", 135, footerY , {
      align: "center",
    });

    // Save PDF
    doc.save(`${formData.name}-${formData.regDate}`);
  };

  const toWords = (num) => {
    // Convert number to words (for simplicity, this can be a simple function or a library)
    // Example function, you may replace it with a more accurate library.
    return num === 20800
      ? "Twenty Thousand Eight Hundred Only"
      : "Amount in words goes here.";
  };

  return (
    <div className="bill-generator-container">
      <div className="form-container">
        <h2>Bill Generator</h2>
        <div className="input-group">
          <div className="input-row">
            <div className="input-field">
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-field">
              <label>Mobile Number:</label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-field">
              <label>Registration Date:</label>
              <input
                type="date"
                name="regDate"
                value={formData.regDate}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-field">
              <label>Package:</label>
              <input
                type="text"
                name="package"
                value={formData.package}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-field">
              <label>Due Amount:</label>
              <input
                type="text"
                name="dueAmount"
                value={formData.dueAmount}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-field">
              <label>Department:</label>
              <input
                type="text"
                name="department"
                value={formData.department}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-field">
              <label>Reg End Date:</label>
              <input
                type="date"
                name="regEndDate"
                value={formData.regEndDate}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-field">
              <label>Paid Amount:</label>
              <input
                type="text"
                name="paidAmount"
                value={formData.paidAmount}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-field">
              <label>Cashier Name:</label>
              <input
                type="text"
                name="cashierName"
                value={formData.cashierName}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-field">
              <label>cashier ID:</label>
              <input
                type="text"
                name="cashierID"
                value={formData.cashierID}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-field">
              <label>Payment Date:</label>
              <input
                type="date"
                name="paymentDate"
                value={formData.paymentDate}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <h3>Payments:</h3>
          {formData.payments.map((payment, index) => (
            <div key={index} className="input-row">
              <div className="input-field">
                <label>Receipt Number:</label>
                <input
                  type="text"
                  name="receiptNumber"
                  value={payment.receiptNumber}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </div>
              <div className="input-field">
                <label>Payment Type:</label>
                <input
                  type="text"
                  name="type"
                  value={payment.type}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </div>
              <div className="input-field">
                <label>Amount Paid:</label>
                <input
                  type="text"
                  name="amount"
                  value={payment.amount}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </div>
            </div>
          ))}
          <button onClick={addPaymentField} className="add-payment-btn">
            Add Another Payment
          </button>
        </div>
      </div>

      <div className="preview-container">
        <h3>Preview:</h3>
        <div className="preview-box">
          <p>Name: {formData.name}</p>
          <p>Mobile Number: {formData.mobile}</p>
          <p>Registration Date: {formData.regDate}</p>
          <p>Package: {formData.package}</p>
          <p>Due Amount: {formData.dueAmount}</p>
          <p>Department: {formData.department}</p>
          <p>Registration End Date: {formData.regEndDate}</p>
          <p>Paid Amount: {formData.paidAmount}</p>
          <p>Payment Date: {formData.paymentDate}</p>
          <p>Cashier Name: {formData.cashierName}</p>
          <p>Cashier ID: {formData.cashierID}</p>

          <h4>Payments:</h4>
          <table className="preview-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Receipt Number</th>
                <th>Payment Type</th>
                <th>Amount Paid</th>
              </tr>
            </thead>
            <tbody>
              {formData.payments.map((payment, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{payment.receiptNumber}</td>
                  <td>{payment.type}</td>
                  <td>{payment.amount}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="3" style={{ textAlign: "right" }}>
                  Grand Total
                </td>
                <td>{calculateGrandTotal()}</td>
              </tr>
            </tbody>
          </table>
          <button onClick={handleDownload} className="download-btn">
            Download PDF
          </button>
        </div>
      </div>
    </div>
  );
}

export default BillGenerator;
