import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import "./BreadCrumbNav.css"; // Add styling

const BreadcrumbNav = () => {
  return (
    <div className="breadcrumb-nav">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Dashboard</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbNav;
