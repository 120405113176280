import React from "react";
// import { users, reports } from "../../data/sampleData";
export const patients = [
  { id: 1, name: "John Doe", age: 45, status: "Checked In" },
  { id: 2, name: "Jane Smith", age: 30, status: "Pending" }
];

export const appointments = [
  { id: 1, patient: "John Doe", doctor: "Dr. Adams", date: "2024-09-10", status: "Confirmed" },
  { id: 2, patient: "Jane Smith", doctor: "Dr. Brown", date: "2024-09-11", status: "Pending" }
];

export const users = [
  { id: 1, name: "Alice Johnson", role: "Receptionist" },
  { id: 2, name: "Mark Green", role: "Manager" }
];

export const reports = [
  { id: 1, report: "Appointment Summary", date: "2024-09-01" },
  { id: 2, report: "Earnings Report", date: "2024-09-01" }
];


const AdminDashboard = () => {
  return (
    <div>
      <h1>Admin Dashboard</h1>
      <h2>Users</h2>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            {user.name} - {user.role}
          </li>
        ))}
      </ul>

      <h2>Reports</h2>
      <ul>
        {reports.map((report) => (
          <li key={report.id}>
            {report.report} - {report.date}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminDashboard;
