import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import { Button } from "antd";
import {
  TextField,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import apiClient from "../../../common/LoginV2/axiosClient";
import "./DepartmentPage.css";

const DepartmentPage = () => {
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState("");
  const [doctorDialogOpen, setDoctorDialogOpen] = useState(false);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);
  const [editDepartment, setEditDepartment] = useState(null);
  const [editDepartmentName, setEditDepartmentName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // Fetch departments from API
  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get("/department/users/list");
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching departments:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDepartments();
  }, []);

  // Add a new department
  const handleAddDepartment = async () => {
    if (
      newDepartment &&
      !departments.some((dept) => dept.department === newDepartment)
    ) {
      setLoading(true);
      try {
        const response = await apiClient.post("/department", {
          department: newDepartment,
        });
        setDepartments([...departments, response.data]);
        setNewDepartment("");
      } catch (error) {
        console.error("Error adding department:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Edit an existing department
  const handleEditDepartment = (department) => {
    setEditDepartment(department);
    setEditDepartmentName(department.department);
  };

  const handleSaveEdit = async () => {
    setButtonLoading(true);
    try {
      await apiClient.patch(`/department/${editDepartment.department}`, {
        department: editDepartmentName,
      });
      setDepartments(
        departments.map((dept) =>
          dept.department === editDepartment.department
            ? { ...dept, department: editDepartmentName }
            : dept
        )
      );
      setEditDepartment(null);
      setEditDepartmentName("");
    } catch (error) {
      console.error("Error updating department:", error);
    } finally {
      setButtonLoading(false);
    }
  };

  // Delete department
  const handleDeleteDepartment = async () => {
    setDeleteButtonLoading(true);
    setErrorMessage("");
    try {
      await apiClient.delete(`/department/${departmentToDelete.department}`);
      setDepartments(
        departments.filter(
          (dept) => dept.department !== departmentToDelete.department
        )
      );
      setDeleteDialogOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setErrorMessage(error.response.data.detail);
      } else {
        console.error("Error deleting department:", error);
      }
    } finally {
      setDeleteButtonLoading(false);
    }
  };

  const handleViewDoctors = (department) => {
    setSelectedDoctors(department.doctors.map((doctor) => doctor.name));
    setDoctorDialogOpen(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Sl. No",
        accessor: (_, rowIndex) => rowIndex + 1,
        id: "index",
      },
      { Header: "Department Name", accessor: "department" },
      {
        Header: "Doctors",
        accessor: "doctors",
        Cell: ({ row }) =>
          row.original.doctors && row.original.doctors.length > 0 ? (
            <Link component="button" onClick={() => handleViewDoctors(row.original)}>
              View Doctors
            </Link>
          ) : (
            <span>No doctors assigned</span>
          ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="actions-cell">
            <Button
              onClick={() => handleEditDepartment(row.original)}
              style={{ marginLeft: "10px" }}
            >
              Edit
            </Button>
            <Button
              color="error"
              loading={buttonLoading && departmentToDelete === row.original}
              onClick={() => {
                setDeleteDialogOpen(true);
                setDepartmentToDelete(row.original);
              }}
              style={{ marginLeft: "10px" }}
            >
              Delete
            </Button>
          </div>
        ),
      },
    ],
    [departments, buttonLoading, departmentToDelete]
  );

  const data = React.useMemo(() => departments, [departments]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="department-management-container">
      <h2>Department Management</h2>
      <div className="add-department-container">
        <TextField
          label="Add new department"
          variant="outlined"
          value={newDepartment}
          onChange={(e) => setNewDepartment(e.target.value)}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddDepartment}
          disabled={loading}
        >
          Add
        </Button>
      </div>

      {loading ? (
        <div className="loading-container">
          <CircularProgress />
          <p>Loading departments...</p>
        </div>
      ) : departments.length === 0 ? (
        <p>No departments created at this time</p>
      ) : (
        <table {...getTableProps()} className="department-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {/* Doctor Dialog */}
      <Dialog
        open={doctorDialogOpen}
        onClose={() => setDoctorDialogOpen(false)}
      >
        <DialogTitle>Doctors Assigned</DialogTitle>
        <DialogContent>
          <List>
            {selectedDoctors.length > 0 ? (
              selectedDoctors.map((doctor, index) => (
                <ListItem key={index}>
                  <ListItemText primary={doctor} />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No doctors assigned." />
              </ListItem>
            )}
          </List>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this department?</p>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteDepartment}
            color="error"
            loading={deleteButtonLoading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Department Dialog */}
      <Dialog
        open={Boolean(editDepartment)}
        onClose={() => setEditDepartment(null)}
      >
        <DialogTitle>Edit Department</DialogTitle>
        <DialogContent>
          <TextField
            label="Department Name"
            variant="outlined"
            fullWidth
            value={editDepartmentName}
            onChange={(e) => setEditDepartmentName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDepartment(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} color="primary" disabled={buttonLoading}>
            {buttonLoading ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DepartmentPage;
