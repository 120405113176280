import React, { useState, useEffect } from 'react';
import './Testimony.css';

const testimonials = [
  {
    name: "Verified Patient",
    location: "Hyderabad",
    rating: 4.5,
    image: "path_to_image", // Replace with actual image path
    review: "I am 23 years old suffering from migraine from past 7yrs it used to affect my studies and during exams, I got referred by my uncle who used to go for his gastric issue my migraine got reduced within 3-5 sessions once I completed my course of yoga Kariya,acupuncture and homeopathy medicine I am completely fine now, about the doctor chirag is friendly and knowledgeable guided me how to lead a healthy life with yoga and exercise thank you sir."
  },
  {
    name: "Verified Patient",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "Nice place for heeling your condition.Best doctor guided me regarding my condition and how to overcome diabetes with the help of diet, yoga and acupuncture within 3 months my diabetes came to normal hba1c reduced from 11 to 5.7 thank you Dr holistic harmony team and Dr Chirag.."
  },
  {
    name: "Verified Patient",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "March 2024, I suffered severe sinus, I went to western medicine, but it didn't work.I was not feeling well for more than one month then and symptoms were not improved.Later along with my friend , I met Dr. Chirag, he listened carefully to my conditions and treated me. Surprisingly, I felt much better even during the treatment and then recovered speedy.He is very concerned and care about patients, remembers patients' symptoms and every details, full of enthusiasm.Here I strongly recommend everyone to go and meet Dr. Chira M S,"
  },
  {
    name: "Mr Nicholas",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "Dr.Chirag is really good with Accupuncture Therapy.I would say that he knows what he is doing and starting the treatment course with diagnosing you well before taking any steps.I struggled a lot with acid reflux and GERD symptoms. With much disappointment in trying to manage it on my own only put much stress and anxiety. But Dr. Chirag helped me to know what foods trigger inflammation and what don't. That really helped in simplifying the process and tackling acid reflux with a straightforward approach. With 10-15 sessions, I would say my acidity symptoms are far better and almost zero now."
  },
  {
    name: "Verified Patient",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "I recently visited Dr. Care Clinic for treatment of my acne, scars, and hair fall, and I couldn't be more pleased with the results. From the moment I walked in, I was greeted by a welcoming and professional staff who made me feel at ease. Dr. Chirag's expertise and compassionate approach were evident from the start. His acupuncture therapy has made a significant difference in my condition. After several sessions, my skin has cleared up remarkably, and my hair fall has decreased noticeably. The improvement in my skin and hair health has boosted my confidence tremendously."
  },
  {
    name: "Verified Patient",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "Great at explaining problems as well as giving professional advice to address them. Care was gentle and effective."
  },
  {
    name: "Mr Harish",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "I am writing this review on behalf of my mom, who had the privilege of receiving acupuncture treatment from Dr. Chirag M S. It is with immense gratitude and a sense of wonder that I share the transformative impact Dr. Chirag M S had on my mom's health and well-being.From the very first appointment, Dr. Chirag M S demonstrated exceptional compassion and genuine care for my mom's health concerns. They took the time to listen attentively, making her feel valued and heard."
  },
  {
    name: "Verified Patient",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "I wanted to express my heartfelt thanks for the remarkable acupuncture treatment Which is provided by Dr.Chirag M S for my thyroid condition. when i first Consulted Dr.Chirag at Februray-2024 My TSH Value was 14.7mcIU/mL, Within 1 Month of acupuncture treatment now its 4.33mcIU/mL. Your expertise and care have truly made a difference. I feel rejuvenated and grateful for the improvement in my health. Thank you for your exceptional care Dr.Chirag Sir."
  },
  {
    name: "Malathi B. S",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "I had trigger finger problem and I had consulted Dr Chirag for treatment. Initially I was a little doubtful of how effective the treatment would be. But on the first day he spent some time to understand my medical history and assured me that it can be treated completely by acupuncture. And now 8 sessions later, I feel like it's 95% cured. I have 2 more sessions left and I'm positive it will be completely cured.Additionally, cost of each session were reasonable as well."
  },
  {
    name: "Verified Patient",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "Dr.chirag is so good in his skills... I got treatment for back pain and neck pain mainly.. and also detected migraine and vertigo.. he explained my health issues and gave proper treatment for it .. I recommend him completely.."
  },
  {
    name: "Verified Patient",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "Feeling much better after the treatment! I came here for my cold and headache problem, and it was also my first my undertaking an acupuncture treatment. After the treatment and doctor's patient explanation, I felt the condition of my body became better and lighter."
  },
  {
    name: "Pankaj kapoor",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "Dr Chirag gave a patient hearing to the health problem and having a proper understanding advised the process of treatment to be followed.On his advice the treatment was undertaken and results were felt but as he explained that the process involved lifestyle management for which a document was prepared by him."
  },
  {
    name: "Verified Patient",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "It was a great experience working with Dr. Chirag. My husband had a fall and fractured his tail bone.Dr. Chirag started the course and within 1 week my husband felt much better.He’s an excellent doctor and educated me on how acupuncture can help in healing my husband’s condition.I’d like to take this moment to thank Latha teacher who referred us to Dr. Chirag."
  },
  {
    name: "Latha Madhusudhan",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "Dr.Chirag is friendly and went out of the way to make my elderly mother- a dementia patient very comfortable. His Acupuncture techniques have helped calm her down and this brought the caregivers and t the patient almost 80 percent relief! His amicable and friendly disposition helps greatly in inserting the Accupuncture needles as he keeps up a banter with the patient .Thank you Dr . Chirag"
  },
  {
    name: "Mallika Sriraman",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "He understands and looks at your ailments holistically. He personalises your treatment. The result is you get complete cure and a long term solution. His remedy for my anxiety gave me instant calm and comfort. He is a professional with deep knowledge and a great healing touch. I am so happy I consulted him. His treatment is working ******* for my mother who is in advanced stage of dementia."
  },
  {
    name: "Sara Arakkal",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "I am Sara Arakkal.My family doctor Sagar Simham had referred me to Dr Chirag of Dr Care for consultation and for Acupuncture therapy for my health problems like, stress, BP & diabetics & numbness in my feet.I had almost 10 sessions on the above ailments. Slowly I got cured with Dr Chrag's personal interests of my diets n exercises regularly. I feel 80% of them are done. Also, I was advised to go on physiotherapy for a better relief on my back n knees. "
  },
  {
    name: "Alice George",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "he could understand my problem very accurately. He took a lot of care and caution while treating me as i am senior citizen which i have hardly seen in todays doctors. He was also very regular in followup everyday to check that the treatment was giving me a positive result. That makes a lot of difference for me as i felt he was genuinely interested in solving my illness sincerely. He gave me a lot of tips, precautions, lifestyle routine i should follow to live a painfree life."
  },
  {
    name: "Verified Patient",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "I was suffering from severe neck pain , I took so many medicines but didn't work, then some how I got know about Dr. Chiragh I went and met him, and he examined me thoroughly and he started treatment in first sitting he just kept 3 needles in my hand my pain got reduced within 2 minutes and it was miracle, finally subsiquent sittings my pain got reduced completely.Finally I want to say Dr. Chiragh is very friendly, polite doctor I have never met doctor like him.Thank you so much doc." },
  {
    name: "Verified Patient",
    location: "Bangalore",
    rating: 5,
    image: "path_to_image", // Replace with actual image path
    review: "Dr.Chriag is easily approachable and he listen to your problem clearly , let you know the cause and provide proper guidance along with treatment and he has been very professional in approach and treatment style and i was suffering from back pain and had a very good result from starting day itself and had a relief from the movement he pierced a needle on my body on first day almost like within 2-5 mins i can say !"},
  // Add more testimonials as needed
];

const images = [
    require('../../images/image1.jpg'),
    require('../../images/image2.jpg'),
];

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // Slide change every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const { name, location, rating, image, review } = testimonials[currentIndex];

  return (
    <div className="testimonial-slider">
      <h2>Hear From What Our Patients Say.</h2>
      <div className="testimonial-content">
        <div className="testimonial-text">
          <h3>{name}</h3>
          <p>{location}</p>
          <div className="testimonial-rating">
            {"★".repeat(Math.floor(rating))}{" "}
            {"☆".repeat(5 - Math.floor(rating))}
          </div>
          <p className="testimonial-review">"{review}"</p>
        </div>
        {/* <img src={images[0]} alt={name} className="testimonial-image" /> */}
      </div>
    </div>
  );
};

export default TestimonialSlider;
