import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from './api';
import './LoginV2.css';
import logo from '../../images/hospitallogo.jpg'; // Assume the logo is stored as logo.png

const LoginV2 = () => {
    const [userName, setuserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // To show loader during login
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);
        setIsLoading(true); // Start loading animation

        try {
            const data = await loginUser(userName, password);
            sessionStorage.setItem('authToken', data.token);
            sessionStorage.setItem('usermanagementId', data.usermanagementId);
            sessionStorage.setItem('userRole', data.role);
            navigate('/dashboard');
        } catch (err) {
            setIsLoading(false); // Stop loading animation

            if (err.response && err.response.status === 401) {
                setError('Invalid userId or password. Please try again.');
            } else if (err.response && err.response.status === 404) {
                setError(`User with userId: ${userName} not found.`);
            } else {
                setError('An error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <img src={logo} alt="Hospital Logo" className="login-logo" />
                <h1 className="login-title">Welcome to Holistic Harmony</h1>
                <p className="login-subtitle">Please log in to access the hospital management system</p>
                {error && <p className="error-message-login">{error}</p>}
                <form onSubmit={handleLogin} className="login-form">
                    <div className="form-group">
                        <label className="form-label">User Id</label>
                        <input
                            placeholder="Enter UserId"
                            value={userName}
                            onChange={(e) => setuserName(e.target.value)}
                            required
                            className="form-input"
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Password</label>
                        <input
                            type="password"
                            placeholder="Enter Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="form-input"
                        />
                    </div>
                    <button type="submit" className="login-button" disabled={isLoading}>
                        {isLoading ? 'Logging in...' : 'Log in'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginV2;
