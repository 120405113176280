import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Sidebar.css"; // Custom CSS for sidebar styling
import {
  FaClipboard,
  FaFileAlt,
  FaUser,
  FaSignOutAlt,
  FaBars,
  FaChartLine,
  FaShoppingCart,
} from "react-icons/fa";

const Sidebar = ({ isMobile }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userRole, setUserRole] = useState(null); // Store the role
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch role from sessionStorage
    const role = sessionStorage.getItem("userRole"); // Adjust key if different
    setUserRole(role);
  }, []);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (isMobile) {
    // No Sidebar on mobile, return null or empty component
    return null;
  }

  const handleLogout = () => {
    // Clear session storage
    sessionStorage.clear();

    // Redirect to login page
    navigate("/login");
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="sidebar-header">
        <FaBars className="toggle-btn" onClick={toggleSidebar} />
        {!isCollapsed && <h1>Dr. Holistic Harmony</h1>}
      </div>
      <ul className="menu-items">
        {/* Admin: Show All Menu Items */}
        {userRole === "admin" && (
          <>
            <li>
              <Link to="/dashboard">
                <FaClipboard className="menu-icon" />
                {!isCollapsed && <span>Dashboard</span>}
              </Link>
            </li>
            <li>
              <Link to="/DepartmentPage">
                <FaChartLine className="menu-icon" />
                {!isCollapsed && <span>Department Management</span>}
              </Link>
            </li>
            <li>
              <Link to="/UserManagementPage">
                <FaUser className="menu-icon" />
                {!isCollapsed && <span>User Management</span>}
              </Link>
            </li>
            <li>
              <Link to="/AppointmentManagementPage">
                <FaClipboard className="menu-icon" />
                {!isCollapsed && (
                  <span>Patient Registration / Create Appointment</span>
                )}
              </Link>
            </li>
            <li>
              <Link to="/showAppointments">
                <FaFileAlt className="menu-icon" />
                {!isCollapsed && <span>Appointments</span>}
              </Link>
            </li>
            <li>
              <Link to="/AppointmentRecordManagement">
                <FaFileAlt className="menu-icon" />
                {!isCollapsed && <span>Treatment Record</span>}
              </Link>
            </li>
            <li>
              <Link to="/PatientRecordsAndBilling">
                <FaFileAlt className="menu-icon" />
                {!isCollapsed && <span>Patient Records / Billing</span>}
              </Link>
            </li>
            <li>
              <Link to="/billing-darkMode">
                <FaShoppingCart className="menu-icon" />
                {!isCollapsed && <span>Manual Billing</span>}
              </Link>
            </li>
          </>
        )}
        {/* Doctor: Show Appointments and Treatment Record */}
        {userRole === "doctor" && (
          <>
            <li>
              <Link to="/showAppointments">
                <FaClipboard className="menu-icon" />
                {!isCollapsed && <span>Appointments</span>}
              </Link>
            </li>
            <li>
              <Link to="/AppointmentRecordManagement">
                <FaFileAlt className="menu-icon" />
                {!isCollapsed && <span>Treatment Record</span>}
              </Link>
            </li>
          </>
        )}
        {/* Receptionist: Show Patient Registration, Appointments, and Treatment Record */}
        {userRole === "receptionist" && (
          <>
            <li>
              <Link to="/PatientRegistration">
                <FaUser className="menu-icon" />
                {!isCollapsed && <span>Patient Registration</span>}
              </Link>
            </li>
            <li>
              <Link to="/showAppointments">
                <FaClipboard className="menu-icon" />
                {!isCollapsed && <span>Appointments</span>}
              </Link>
            </li>
            <li>
              <Link to="/AppointmentRecordManagement">
                <FaFileAlt className="menu-icon" />
                {!isCollapsed && <span>Treatment Record</span>}
              </Link>
            </li>
            <li>
              <Link to="/PatientRecordsAndBilling">
                <FaFileAlt className="menu-icon" />
                {!isCollapsed && <span>Patient Records</span>}
              </Link>
            </li>
          </>
        )}
      </ul>
      <div className="sidebar-footer">
        <button onClick={handleLogout} className="logout-link">
          <FaSignOutAlt className="menu-icon" />
          {!isCollapsed && <span>Logout</span>}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
