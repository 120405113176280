import React from 'react';
import './Banner.css'; // Create this file for styling

const Banner = ({ message, type, onClose }) => {
    return (
        <div className={`banner ${type}`}>
            <div className="banner-content">
                <p>{message}</p>
                <button onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

export default Banner;
