import axios from 'axios';

const API_URL = 'https://lbtvbv4sqh.execute-api.ap-south-1.amazonaws.com/dev/v1/user/login'; // Replace with your actual API endpoint

export const loginUser = async (userName, password) => {
    try {
        const response = await axios.post(API_URL, {
            userName,
            password,
        });

        if (response.data && response.data.token) {
            return response.data;
        } else {
            throw new Error('Authentication failed');
        }
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};
