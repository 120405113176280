// import hospitalLogo from "../../images/hospitallogo.jpg";
// import React from "react";
// import "./Header.css";

// const Header = () => {
//   return (
//     <header>
//       <div className="top-bar">
//         <div className="contact-info">
//           <a href="mailto:info@example.com">drholisticharmony@gmail.com</a>
//           <span> | </span>
//           <a href="#contact">Contact Us: +91 91751 81049 </a>
//         </div>
//       </div>
//       <nav className="navbar">
//         <div className="logo">
//           <img src={hospitalLogo} alt="Hospital Logo" />
//           {/* <h1>Mecare</h1> */}
//         </div>
//         <h1 className="hospitalName">Dr. Holistic Harmony</h1>
//         <ul className="nav-links">
//           <li>
//             <a href="#consultation-hours">Consultation Hours</a>
//           </li>
//           <li>
//             <a href="#consultation-location">Location</a>
//           </li>
//           <li>
//             <a href="#services">Our Services</a>
//           </li>
//           <li>
//             <a href="#about-us">About Us</a>
//           </li>
//           <li>
//             <a href="#appointment">Book an Appointment</a>
//           </li>
//         </ul>
//       </nav>
//     </header>
//   );
// };

// export default Header;

import hospitalLogo from "../../images/hospitallogo.jpg";
import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <header>
      <div className="top-bar">
        <div className="contact-info">
          <a href="mailto:info@example.com">drholisticharmony@gmail.com</a>
          <span> | </span>
          <span>Contact Us: </span>
          <a href="tel:+917204461364">+91 7204461364 </a>
          <span>/ </span>
          <a href="tel:+919175181049"> +91 9175181049</a>
        </div>
      </div>
      <nav className="navbar">
        <div className="logo">
          <img src={hospitalLogo} alt="Hospital Logo" />
        </div>
        <h1 className="hospitalName">Dr. Holistic Harmony</h1>
        <ul className="nav-links">
          <li>
            <a href="#consultation-hours">Consultation Hours</a>
          </li>
          <li>
            <a href="#consultation-location">Location</a>
          </li>
          <li>
            <a href="#services">Our Services</a>
          </li>
          <li>
            <a href="#about-us">About Us</a>
          </li>
          <li>
            <a href="#appointment">Book an Appointment</a>
          </li>
        </ul>
      </nav>
      <div className="carousal-banner">
        <marquee behavior="scroll" direction="left">
          <span>🌟 Limited-Time Inaugural Offers!!, Grab Them Before They’re Gone!</span>
          <span> ✨ Special Introductory Prices!! Don't Miss Out, Act Now!</span> 
          <span> ✨ We combine Acupuncture/diet/yoga/homeopathy medicine</span> 
        </marquee>
      </div>
      <div className="promo-banner">
        <h2>Exclusive Offers like never before!</h2>
        <div className="offer-grid">
          <div className="offer-card">
            <div className="offer-badge">🔴 Hair Growth Treatment</div>
            <div className="offer-content">
              <span>💉 PRP starting from ₹2,500</span>
              <span>💉 GFC starting from ₹3,500</span>
            </div>
            <a href="#appointment" className="offer-button">
              Contact Us
            </a>
          </div>
          <div className="offer-card">
            <div className="offer-badge">🧘 Yoga</div>
            <div className="offer-content">
              <span>🧘‍♂️ Yoga Classes</span>
              <span>starting from ₹1,299</span>
            </div>
            <a href="#appointment" className="offer-button">
              Contact Us
            </a>
          </div>
          <div className="offer-card">
            <div className="offer-badge">🥗 Diet</div>
            <div className="offer-content">
              <span>🥗 Diet Plans</span>
              <span>starting from ₹1,499</span>
            </div>
            <a href="#appointment" className="offer-button">
            Contact Us  
            </a>
          </div>
          <div className="offer-card">
            <div className="offer-badge">💆 Acupuncture & Physiotherapy</div>
            <div className="offer-content">
              <span>💆 Acupuncture & Physiotherapy</span>
              <span>starting from ₹650</span>
            </div>
            <a href="#appointment" className="offer-button">
            Contact Us
            </a>
          </div>
          <div className="offer-card">
            <div className="offer-badge">🌿 Homeopathy</div>
            <div className="offer-content">
              <span>🌿 Homeopathy Package</span>
              <span>starting from ₹1,199</span>
            </div>
            <a href="#appointment" className="offer-button">
            Contact Us
            </a>
          </div>
          <div className="offer-card">
            <div className="offer-badge">🔵 Reversal Program</div>
            <div className="offer-content">
              <span>💎 3 - 6 months Reversal Program</span>
              <span> starting from ₹4,999 / month</span>
              <span>Diabetes/Obesity/Thyroid/Pcod/PCOS/Infertility</span>
            </div>
            <a href="#appointment" className="offer-button">
            Contact Us
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
