import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css"; // Custom CSS for dashboard styling
import {
  FaUserInjured,
  FaCog,
  FaClipboard,
  FaChartLine,
  FaNotesMedical,
  FaCashRegister,
  FaUser
} from "react-icons/fa";

const Dashboard = () => {
  const history = useNavigate();
  const [role, setRole] = useState("");

  useEffect(() => {
    // Fetch role from sessionStorage or any other secure storage
    const token = sessionStorage.getItem("userRole");
    if (token) {
      setRole(token); // Assuming the token directly gives the role
    } else {
      // Redirect to login if token is missing
      history("/login");
    }
  }, [history]);

  const handleCardClick = (path) => {
    history(path);
  };

  const doctorCards = (
    <>
      <div
        className="card"
        onClick={() => handleCardClick("/AppointmentRecordManagement")}
      >
        <FaCashRegister className="card-icon" />
        <span>Treatment Record</span>
      </div>
      <div
        className="card"
        onClick={() => handleCardClick("/showAppointments")}
      >
        <FaCashRegister className="card-icon" />
        <span>Appointments</span>
      </div>
    </>
  );

  const receptionistCards = (
    <>
      <div
        className="card"
        onClick={() => handleCardClick("/AppointmentManagementPage")}
      >
        <FaNotesMedical className="card-icon" />
        <span>Patient Registration / Create Appointment</span>
      </div>
      <div
        className="card"
        onClick={() => handleCardClick("/showAppointments")}
      >
        <FaCashRegister className="card-icon" />
        <span>Appointments</span>
      </div>
      <div
        className="card"
        onClick={() => handleCardClick("/AppointmentRecordManagement")}
      >
        <FaCashRegister className="card-icon" />
        <span>Treatment Record</span>
      </div>
      <div
        className="card"
        onClick={() => handleCardClick("/PatientRecordsAndBilling")}
      >
        <FaCashRegister className="card-icon" />
        <span>Patient Records / Billing</span>
      </div>
    </>
  );

  const adminCards = (
    <>
          <div
        className="card"
        onClick={() => handleCardClick("/dashboard")}
      >
        <FaClipboard className="card-icon" />
        <span>Dashboard</span>
      </div>
      <div
        className="card"
        onClick={() => handleCardClick("/DepartmentPage")}
      >
        <FaChartLine className="card-icon" />
        <span>Department Management</span>
      </div>
      <div
        className="card"
        onClick={() => handleCardClick("/UserManagementPage")}
      >
        <FaUser className="card-icon" />
        <span>User Management</span>
      </div>
      <div
        className="card"
        onClick={() => handleCardClick("/AppointmentManagementPage")}
      >
        <FaNotesMedical className="card-icon" />
        <span>Patient Registration / Create Appointment</span>
      </div>
      <div
        className="card"
        onClick={() => handleCardClick("/showAppointments")}
      >
        <FaCashRegister className="card-icon" />
        <span>Appointments</span>
      </div>
      <div
        className="card"
        onClick={() => handleCardClick("/AppointmentRecordManagement")}
      >
        <FaCashRegister className="card-icon" />
        <span>Treatment Record</span>
      </div>
      <div
        className="card"
        onClick={() => handleCardClick("/PatientRecordsAndBilling")}
      >
        <FaCashRegister className="card-icon" />
        <span>Patient Records / Billing</span>
      </div>
      <div
        className="card"
        onClick={() => handleCardClick("/billing-darkMode")}
      >
        <FaCashRegister className="card-icon" />
        <span>Manual Billing</span>
      </div>
    </>
  );

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-title">Dashboard</h1>
      <div className="card-grid">
        {role === "doctor" && doctorCards}
        {role === "receptionist" && receptionistCards}
        {role === "admin" && adminCards}
        {!role && <p>Loading...</p>}
      </div>
    </div>
  );
};

export default Dashboard;
