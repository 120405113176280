import React, { useState, useEffect } from "react";
import { Table, Button, Input, DatePicker, Modal, message, Select } from "antd";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import apiClient from "../../../common/LoginV2/axiosClient";
import { CircularProgress } from "@mui/material";
import "./ShowAppointments.css";

const { Option } = Select;

// Validation schema for the registration form
const schema = yup.object().shape({
  doctor: yup.string().required("Doctor is required"),
  date: yup
    .date()
    .required("Appointment date is required")
    .typeError("Invalid date"),
  appointmentTime: yup.string().required("Appointment time is required"), // Add validation for time
});

const ShowAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [loading, setLoading] = useState(false);
  const [saveButtonloading, setSaveButtonLoading] = useState(false);
  const [doctorsLoading, setDoctorsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    fetchAppointments();
    fetchDoctors(); // Fetch doctors list on mount
  }, []);

  // Fetch appointments from API
  const fetchAppointments = async (date = null, page = 1) => {
    setLoading(true);
    try {
      let url = `/appointment?page=${page}&limit=10`;

      // Append the date filter if a date is selected
      if (date) {
        url += `&filters=appointmentDate:eq:${date}`;
      }

      const response = await apiClient.get(url);
      setAppointments(response.data.items);
      setFilteredAppointments(response.data.items);
      setTotalAppointments(response.data.totalItems);
    } catch (error) {
      message.error("Failed to fetch appointments");
    } finally {
      setLoading(false);
    }
  };

  // Fetch doctors list from API
  const fetchDoctors = async () => {
    setDoctorsLoading(true);
    try {
      const response = await apiClient.get("/user");
      const doctorData = response.data.items
        .filter((doctor) => doctor.role === "doctor")
        .map((doctor) => ({
          id: doctor.id,
          name: `${doctor.firstName} ${doctor.lastName}`,
        }));
      setDoctors(doctorData);
    } catch (error) {
      message.error("Failed to fetch doctors");
    } finally {
      setDoctorsLoading(false);
    }
  };

  // Handle search by patient name
  const handleSearch = (value) => {
    setSearchText(value?.target?.value);
  };

  useEffect(() => {
    const filteredAppointments = appointments.filter(
      (appointment) =>
        appointment?.patientName
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()) ||
        appointment?.patientName?.includes(searchText)
    );
    setFilteredAppointments(filteredAppointments);
  }, [searchText]);

  useEffect(() => {
    if (selectedAppointment) {
      setValue("doctor", selectedAppointment.doctorId);
      setValue(
        "date",
        moment(selectedAppointment.appointmentDate).format("YYYY-MM-DD")
      );
      setValue("appointmentTime", selectedAppointment.appointmentTime);
    }
  }, [selectedAppointment, setValue]);

  // Handle date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    fetchAppointments(date ? date.format("YYYY-MM-DD") : null, currentPage);
  };

  // Handle pagination
  const handlePaginationChange = (page) => {
    setCurrentPage(page);
    fetchAppointments(
      selectedDate ? selectedDate.format("YYYY-MM-DD") : null,
      page
    );
  };

  const handleDeleteAppointment = async (appointmentId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this appointment?",
      onOk: async () => {
        try {
          await apiClient.delete(`/appointment/${appointmentId}`);
          const updatedAppointments = appointments.filter(
            (appointment) => appointment.id !== appointmentId
          );
          setAppointments(updatedAppointments);
          message.success("Appointment deleted successfully");
        } catch (error) {
          message.error("Failed to delete appointment");
        }
      },
    });
  };

  const handleEditAppointment = (appointment) => {
    setSelectedAppointment(appointment);
    setIsEditModalVisible(true);

    // Populate the form with existing data
    setValue("doctor", appointment.doctorId);
    setValue("date", moment(appointment.appointmentDate).format("YYYY-MM-DD"));
    setValue("appointmentTime", appointment.appointmentTime);
  };

  const handleSaveEdit = async (data) => {
    setSaveButtonLoading(true);
    try {
      // Ensure the date is properly formatted
      const formattedDate = moment(data.date).format("YYYY-MM-DD");

      const response = await apiClient.patch(
        `/appointment/${selectedAppointment.id}`,
        {
          departmentId: selectedAppointment.departmentId,
          appointmentDate: formattedDate, // Use formatted date
          doctorId: data.doctor,
          appointmentTime: data.appointmentTime,
        }
      );

      const updatedAppointment = response.data;

      const updatedAppointments = appointments.map((appointment) =>
        appointment.id === selectedAppointment.id
          ? updatedAppointment
          : appointment
      );
      setAppointments(updatedAppointments);
      setIsEditModalVisible(false);
      message.success("Appointment updated successfully");
    } catch (error) {
      message.error("Failed to update appointment");
    } finally {
      setSaveButtonLoading(false);
    }
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      render: (text) => text || "Unknown Patient",
    },
    {
      title: "Doctor",
      dataIndex: "doctorId",
      key: "doctorId",
      render: (doctorId) => {
        const doctor = doctors.find((d) => d.id === doctorId);
        return doctor ? doctor.name : "Unknown Doctor";
      },
    },
    {
      title: "Appointment Date",
      dataIndex: "appointmentDate",
      key: "appointmentDate",
    },
    {
      title: "Appointment Time",
      dataIndex: "appointmentTime",
      key: "appointmentTime",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div className="show-appointment-actions">
          <Button onClick={() => handleEditAppointment(record)} type="link">
            Edit
          </Button>
          <Button
            onClick={() => handleDeleteAppointment(record.id)}
            type="link"
            danger
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="appointments-page-container">
      <div className="appointments-page-header">
        <h2>Appointments</h2>
        <div className="appointments-page-search">
          <Input.Search
            placeholder="Search by patient name"
            onChange={handleSearch}
            style={{ width: 300, marginBottom: 20 }}
          />
          <DatePicker
            onChange={handleDateChange}
            style={{ marginBottom: 20, marginRight: 20 }}
            value={selectedDate}
            format="YYYY-MM-DD"
          />
        </div>
      </div>
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
          <p>Loading appointments...</p>
        </div>
      ) : (
        <Table
          className="appointments-display"
          columns={columns}
          dataSource={filteredAppointments}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: totalAppointments,
            onChange: handlePaginationChange,
          }}
        />
      )}

      {/* Edit Appointment Modal */}
      <Modal
        title="Edit Appointment"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        {selectedAppointment && (
          <form onSubmit={handleSubmit(handleSaveEdit)} className="form">
            <div className="form-group">
              <label htmlFor="doctor">Doctor</label>
              <Select
                id="doctor"
                defaultValue={selectedAppointment.doctorId}
                onChange={(value) => setValue("doctor", value)}
                {...register("doctor")}
                className={errors.doctor ? "input-error" : ""}
              >
                {doctors.map((doctor) => (
                  <Option key={doctor.id} value={doctor.id}>
                    {doctor.name}
                  </Option>
                ))}
              </Select>
              {errors.doctor && (
                <p className="error-message">{errors.doctor.message}</p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                {...register("date")}
                defaultValue={moment(
                  selectedAppointment.appointmentDate
                ).format("YYYY-MM-DD")}
                onChange={(e) => setValue("date", e.target.value)}
                className={errors.date ? "input-error" : ""}
              />

              {errors.date && (
                <p className="error-message">{errors.date.message}</p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="appointmentTime">Appointment Time</label>
              <input
                type="time"
                id="appointmentTime"
                {...register("appointmentTime")}
                defaultValue={selectedAppointment.appointmentTime}
                onChange={(e) => setValue("appointmentTime", e.target.value)}
                className={errors.appointmentTime ? "input-error" : ""}
              />
              {errors.appointmentTime && (
                <p className="error-message">
                  {errors.appointmentTime.message}
                </p>
              )}
            </div>

            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "10px" }}
                loading={saveButtonloading}
              >
                Save
              </Button>
              <Button onClick={() => setIsEditModalVisible(false)}>
                Cancel
              </Button>
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default ShowAppointments;
