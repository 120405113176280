import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import apiClient from '../LoginV2/axiosClient';
import './PasswordUpdate.css';

// Define schema for validation
const schema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-zA-Z]/, 'Password must contain both letters and numbers')
    .required('New password is required'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm your new password'),
});

const PasswordUpdate = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      // Replace with your actual API endpoint
      const usermanagementId = sessionStorage.getItem('usermanagementId');
      const response = await apiClient.patch(`/user/${usermanagementId}`, {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      });

      if (response.status === 200) {
        alert('Password updated successfully');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Handle specific error from API response
        alert(error.response.data.message);
      } else {
        alert('An error occurred while updating the password. Please try again.');
      }
    }
  };

  return (
    <div className="password-container">
      <h1>Update Password</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="form-group">
          <label htmlFor="currentPassword">Current Password</label>
          <input
            type="password"
            id="currentPassword"
            {...register('currentPassword')}
            className={`input ${errors.currentPassword ? 'input-error' : ''}`}
          />
          {errors.currentPassword && <p className="error-message">{errors.currentPassword.message}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            {...register('newPassword')}
            className={`input ${errors.newPassword ? 'input-error' : ''}`}
          />
          {errors.newPassword && <p className="error-message">{errors.newPassword.message}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm New Password</label>
          <input
            type="password"
            id="confirmPassword"
            {...register('confirmPassword')}
            className={`input ${errors.confirmPassword ? 'input-error' : ''}`}
          />
          {errors.confirmPassword && <p className="error-message">{errors.confirmPassword.message}</p>}
        </div>

        <button type="submit" className="submit-button">Update Password</button>
      </form>
    </div>
  );
};

export default PasswordUpdate;
