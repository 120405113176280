import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col, message } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import apiClient from "../../../../common/LoginV2/axiosClient";
import hospitalLogo from "../../../../images/hospitallogo.jpg";
import jsPDF from "jspdf";
import "jspdf-autotable";

const GenerateBillModal = ({ visible, onClose, patientInfo }) => {
  console.log(patientInfo,"patientInfo")
  const [form] = Form.useForm();
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [billPaidAmount, setBillPaidAmount] = useState(0);
  const [receiptNumber, setReceiptNumber] = useState("");

  const totalWords = (num) => {
    // Convert number to words (for simplicity, this can be a simple function or a library)
    // Example function, you may replace it with a more accurate library.
    const ones = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousands = ["", "Thousand", "Million", "Billion"];

    if (num === 0) return "Zero";

    let word = "";
    let i = 0;

    while (num > 0) {
      if (num % 1000 !== 0) {
        word =
          (num % 100 < 20
            ? teens[(num % 100) - 10] || ones[num % 10]
            : tens[Math.floor((num % 100) / 10)] + " " + ones[num % 10]) +
          " " +
          thousands[i] +
          " " +
          word;
      }
      num = Math.floor(num / 1000);
      i++;
    }

    return word.trim() + " Only";
  };

  useEffect(() => {
    if (visible) {
      const generatedReceiptNumber = Math.floor(
        100000000000 + Math.random() * 900000000000
      ).toString();
      setReceiptNumber(generatedReceiptNumber);

      form.setFieldsValue({
        patientName: patientInfo?.patientName || "",
        phoneNumber: patientInfo?.phoneNumber || "",
      });
    }
  }, [visible, patientInfo, form]);

  const handleAmountChange = () => {
    const values = form.getFieldsValue();
    const totalBillingAmount = values.packages.reduce(
      (sum, pkg) => sum + Number(pkg.billingAmount || 0),
      0
    );
    const remaining = totalBillingAmount + patientInfo?.previousBalance - (values.paidAmount || 0);
    setBillPaidAmount(values.paidAmount)
    setRemainingBalance(remaining >= 0 ? remaining : 0);
  };

  // const handleGenerateBill = async (values) => {
  //   const billData = {
  //     patientId: patientInfo.patientId,
  //     appointmentId: Object.values(patientInfo.records)[0].details.appointmentId,
  //     packages: values.packages,
  //     paidAmount: values.paidAmount,
  //     totalRemainingAmount: remainingBalance,
  //     receiptNumber,
  //     cashierName: values.cashierName || '',
  //     cashierId: values.cashierId || '',
  //   };

  //   try {
  //     await apiClient.post('/generateBill', billData);
  //     message.success('Bill generated successfully');
  //     form.resetFields();
  //     onClose();
  //   } catch (error) {
  //     message.error('Failed to generate bill');
  //   }
  // };

  const handleGenerateBill = async () => {
    // Prepare the bill data object with all the form fields
    const values = form.getFieldsValue();
    const totalBillingAmount = values?.packages?.reduce(
      (sum, pkg) => sum + Number(pkg?.billingAmount || 0),
      0
    );
    const billData = {
      patientId: patientInfo.patientId,
      appointmentId: Object.values(patientInfo.records)[0].details
        .appointmentId,
        packages: values.packages,
      paidAmount: billPaidAmount,
      previousRemainingAmount: patientInfo.previousBalance,
      totalRemainingAmount: remainingBalance,
      totalAmount: totalBillingAmount,
      receiptNumber,
    };

    try {
      // Make the API POST request to generate the bill
      const response = await apiClient.post("/billing", billData);

      // Check if the response is successful
      if (response.status === 200) {
        message.success("Bill generated successfully");

        // Reset the form and close the modal after successful generation
        form.resetFields();
        onClose();
      }
    } catch (error) {
      // Handle any error from the API request
      message.error("Failed to generate bill");
      console.error(error);
    }
  };

  // Function to generate the PDF
  const handleDownloadBill = () => {
    const values = form.getFieldsValue();
    const doc = new jsPDF();

    // Add your company logo (uncomment if needed)
    doc.addImage(hospitalLogo, "PNG", 0, 5, 70, 40);

    // Set font size and style for the address and phone number
    doc.setFont("helvetica", "bold");
    doc.setFontSize(9); // Reduce font size as needed

    // Define vertical spacing
    const lineHeight = 5;
    const numberHeight = 6; // Adjust line height to control the gap between lines

    // Add address lines
    doc.text("4th floor, Patel Hanumakka complex,", 140, 20);
    doc.text("Hennur Bagalur Main Rd, above HDFC ", 140, 20 + lineHeight); // Adjust y-coordinate for spacing
    doc.text("Bank, Kothanur, Bengaluru - 560077", 140, 20 + 2 * lineHeight); // Adjust y-coordinate for spacing

    // Add phone number
    doc.text("Phone Number: +91 91751 81049", 140, 20 + 3 * numberHeight); // Adjust y-coordinate for spacing

    // Receipt Section
    doc.setFontSize(18);
    doc.text("Receipt", 95, 40, { align: "center" });
    doc.setLineWidth(0.5);
    doc.line(10, 45, 200, 45);

    // Details Section
    doc.setFontSize(12);
    const startY = 55;
    const col1X = 10;
    const col2X = 125;

    const totalBillingAmount = values?.packages?.reduce(
      (sum, pkg) => sum + Number(pkg?.billingAmount || 0),
      0
    );
    doc.text(`Name: ${values.patientName}`, col1X, startY);
    doc.text(`Mobile Number: ${values.phoneNumber}`, col2X, startY);
    doc.text(
      `Cashier Name: ${values.cashierName || "N/A"}`,
      col1X,
      startY + 10
    );
    doc.text(`Cashier ID: ${values.cashierId || "N/A"}`, col2X, startY + 10);
    doc.text(`Total Amount: ${totalBillingAmount}`, col1X, startY + 20);
    doc.text(`Paid Amount: ${values.paidAmount}`, col2X, startY + 20);
    doc.text(`Due Amount: ${remainingBalance}`, col1X, startY + 30);

    // Add package details
    const packagesData = values.packages.map((pkg, index) => [
      index + 1,
      pkg.packageName,
      pkg.billingAmount,
    ]);

    doc.autoTable({
      startY: startY + 40,
      head: [["S.No", "Package Name", "Billing Amount"]],
      body: packagesData,
      theme: "grid",
      headStyles: { fillColor: [22, 160, 133] }, // Header color (customizable)
      bodyStyles: { fontSize: 10, halign: "center" }, // Body styling (centered, font size)
    });

    // Add Grand Total below the table
    const grandTotalY = startY + 70;

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(`Grand Total: ${totalBillingAmount}`, col2X, grandTotalY);

    // Total in Words
    // Assuming you have initialized your jsPDF instance as `doc`
    const grandTotalWords = totalWords(totalBillingAmount);

    const wordsY = grandTotalY;

    doc.text("In words: ", col1X, wordsY);
    doc.setFont("helvetica", "normal");
    doc.text(grandTotalWords, col1X + doc.getTextWidth("In words: "), wordsY);

    // Terms and Conditions
    const termsY = doc.autoTable.previous.finalY + 20;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("Terms and Conditions", 105, termsY, { align: "center" });
    doc.setLineWidth(0.5);
    doc.line(10, termsY + 5, 200, termsY + 5); // Line below the heading
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    let currentY = termsY + 15; // Start from here for terms and conditions

    // Loop through terms and add them to the PDF
    const terms = [
      "• The facilities of joining the card include any number of consultations with a physician",
      "• The card facilities are given only to the one on whose name the card is made.",
      "• The fee is non-transferable, non-refundable, and non-extendable.",
      "• Patients are strictly advised to use medicines as per attending physician's recommendation.",
      "• We expect and would appreciate patients to visit the clinic as per the due date of their consultations.",
      "• Patients are requested to cooperate with the mode of treatment, as sometimes, the speed of recovery is slow.",
      "• The duration of treatment and results may vary from patient to patient.",
      "• The doctor and the clinic have given no guarantee to me (the patient) about the results and duration of the treatment.",
      "• During critical emergencies, patients/attendants are advised to inform the attending physician.",
      "• This corporate clinic promises to provide the best service and treatment to all patients.",
    ];

    terms.forEach((term) => {
      doc.text(term, 10, currentY);
      currentY += 10; // Add space between terms
    });

    // Signature Section
    const signatureY = currentY;
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Patient / Attendants Signature: ____________________",
      10,
      signatureY
    );
    doc.text(`Mobile Number: ${values.phoneNumber}`, 10, signatureY + 7);
    doc.text("Authorized Signatory: _________________", 120, signatureY);
    doc.text(`Cashier Name: ${values.cashierName}`, 120, signatureY + 7);
    doc.text(`Cashier ID: ${values.cashierId}`, 120, signatureY + 14);

    doc.line(10, signatureY + 20, 200, signatureY + 20); // Line below signatures

    // Footer
    const footerY = signatureY + 30;
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("Bangalore, Karnataka, India", 55, footerY, { align: "center" });
    doc.text(
      "Email: drholisticharmony.com, Mobile: +91 91751 81049",
      135,
      footerY,
      { align: "center" }
    );

    // Save the PDF
    doc.save(`bill_${values.patientName}_${receiptNumber}.pdf`);
  };

  return (
    <Modal
      title="Generate Bill"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={1000}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ packages: [{ packageName: "", billingAmount: 0 }] }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Patient Name" name="patientName">
              <Input placeholder="Enter Patient Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone Number" name="phoneNumber">
              <Input placeholder="Enter Phone Number" />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="packages">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Row gutter={16} key={field.key}>
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      label="Package Name"
                      name={[field.name, "packageName"]}
                      fieldKey={[field.fieldKey, "packageName"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter package name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Package Name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...field}
                      label="Billing Amount"
                      name={[field.name, "billingAmount"]}
                      fieldKey={[field.fieldKey, "billingAmount"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter billing amount",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter Amount"
                        onChange={handleAmountChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="danger"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(field.name)}
                      style={{ marginTop: "28px" }}
                    />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Package
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Paid Amount" name="paidAmount">
              <Input
                type="number"
                placeholder="Enter Paid Amount"
                onChange={handleAmountChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Remaining Balance">
              <Input value={remainingBalance} readOnly />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Cashier Name" name="cashierName">
              <Input placeholder="Enter Cashier Name (Optional)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Cashier ID" name="cashierId">
              <Input placeholder="Enter Cashier ID (Optional)" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Receipt Number">
              <Input value={receiptNumber} readOnly />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => {
              handleDownloadBill(); // Ensure this function is called
              handleGenerateBill(); // Ensure this function is called
            }}
          >
            Generate Bill and Download PDF
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GenerateBillModal;
