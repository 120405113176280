import React from 'react';
import './HeroSection.css';
// import heroImage from '../assets/hero-image.png'; // Import the hero image
const images = [
    require('../../images/yoga1.jpg'),
    require('../../images/image2.jpg'),
];
const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>Get Better Care For Your <span>Health</span></h1>
        <p>
        Discover a world of holistic wellness at Holistic Harmony. From personalized diet plans to rejuvenating yoga and natural therapies, we're dedicated to helping you achieve optimal health and vitality.
        </p>
        {/* <a href="#" className="learn-more-btn">Learn More</a> */}
      </div>
      <div className="hero-image">
        <img src={images[0]} alt="Healthcare Team" />
      </div>
    </section>
  );
}

export default HeroSection;
