import logo from "./logo.svg";
import MyComponent from "./newComponent";
import "./App.css";
import { Layout } from 'antd';
import HomePage from "./components/Pages/HomePage/HomePage";
import ReceptionistDashboard from "./components/Pages/ReceptionistDashboard/ReceptionistDashboard";
import AdminDashboard from "./components/Pages/AdminDashboard/AdminDashboard";
import AppointmentDashboard from "./components/main/AppointmentDashboard/AppointmentDashboard";
import ShowAppointments from "./components/Pages/AdminDashboard/ShowAppointments/ShowAppointments";
import AppointmentRecordManagement from "./components/Pages/AdminDashboard/AppointmentRecordManagement/AppointmentRecordManagement";
import LoginV2 from "./components/common/LoginV2/LoginV2";
import ManagerDashboard from "./components/Pages/ManagerDashboard/ManagerDashboard";
import DepartmentPage from "./components/Pages/AdminDashboard/DepartmentPage/DepartmentPage";
import PatientRecordsAndBilling from "./components/Pages/AdminDashboard/PatientRecordsAndBilling/PatientRecordsAndBilling";
import UserManagementPage from "./components/Pages/AdminDashboard/UserManagementPage/UserManagementPage";
import AppointmentManagementPage from "./components/Pages/AdminDashboard/AppointmentManagementPage/AppointmentManagementPage";
import BillGenerator from "./components/Pages/BillingPage/BillingPage";
import BreadcrumbNav from "./components/common/BreadCrumbNav/BreadCrumbNav";
import { useState, useEffect } from "react";
import Dashboard from "./components/common/Dashboard/Dashboard";
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./components/common/Sidebar/Sidebar";
import PasswordUpdate from "./components/common/PasswordUpdate/PasswordUpdate";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery } from "react-responsive";

const { Content } = Layout;

const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Add useNavigate to programmatically navigate
  const [userType, setUserType] = useState('admin'); // You can change this based on authentication
  const hideSidebar = ['/login', '/', '/billing-darkMode'].includes(location.pathname);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // Check for authentication token on component mount
  useEffect(() => {
    const authToken = sessionStorage.getItem('authToken');
    // If the token does not exist, redirect to the login page
    if (!authToken) {
      navigate('/login');
    }
  }, [navigate]); // Empty dependency array ensures this runs once when the component mounts

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout style={{ flexDirection: isMobile ? 'column' : 'row' }}>
        {!hideSidebar && !isMobile && <Sidebar userType={userType} isMobile={isMobile} />}
        {!hideSidebar && isMobile && <BreadcrumbNav />}
        <Layout>
          <Content style={{ minHeight: 280 }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="login" element={<LoginV2 />} />
              <Route path="/receptionist" element={<ReceptionistDashboard />} />
              <Route path="/AppointmentDashboard" element={<AppointmentDashboard />} />
              <Route path="/showAppointments" element={<ShowAppointments />} />
              <Route path="/admin" element={<AdminDashboard />} />
              <Route path="/manager" element={<ManagerDashboard />} />
              <Route path="/DepartmentPage" element={<DepartmentPage />} />
              <Route path="/UserManagementPage" element={<UserManagementPage />} />
              <Route path="/AppointmentManagementPage" element={<AppointmentManagementPage />} />
              <Route path="/AppointmentRecordManagement" element={<AppointmentRecordManagement />} />
              <Route path="/PatientRecordsAndBilling" element={<PatientRecordsAndBilling />} />
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/PasswordUpdate" element={<PasswordUpdate />} />
              <Route
                path="/billing-darkMode"
                element={<BillGenerator />}
              />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
