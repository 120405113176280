import React, { useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTable, usePagination } from 'react-table';
import './AppointmentDashboard.css'; // Import CSS for styling

const appointmentsMockData = [
  { id: 1, patientName: 'John Doe', date: '2024-09-05', time: '10:00 AM', doctor: 'Dr. Smith', status: 'Scheduled' },
  { id: 2, patientName: 'Jane Roe', date: '2024-09-05', time: '11:00 AM', doctor: 'Dr. Brown', status: 'Scheduled' },
  { id: 3, patientName: 'Alice Brown', date: '2024-09-06', time: '02:00 PM', doctor: 'Dr. Green', status: 'Rescheduled' },
  // Add more data if needed
];

const AppointmentDashboard = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({
    patientName: '',
    date: '',
    time: '',
    doctor: '',
    status: 'Scheduled'
  });

  const filteredAppointments = useMemo(() => 
    appointmentsMockData
      .filter(app => app.date === selectedDate.toISOString().split('T')[0])
      .filter(app => app.patientName.toLowerCase().includes(searchTerm.toLowerCase())),
    [selectedDate, searchTerm]
  );

  const columns = useMemo(() => [
    { Header: 'Patient Name', accessor: 'patientName' },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Time', accessor: 'time' },
    { Header: 'Doctor', accessor: 'doctor' },
    { Header: 'Status', accessor: 'status' }
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize
  } = useTable(
    { columns, data: filteredAppointments, initialState: { pageIndex: 0 } },
    usePagination
  );

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAppointmentDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleSchedule = () => {
    // Add scheduling logic here
    closeModal();
  };

  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <div className="search-and-date-filter">
        <input
          type="text"
          placeholder="Search by patient name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="yyyy-MM-dd"
          className="date-picker"
        />
        <button className="schedule-button" onClick={openModal}>Schedule Appointment</button>
      </div>
      <div className="table-container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!pageIndex}>First</button>
          <button onClick={() => previousPage()} disabled={!pageIndex}>Previous</button>
          <button onClick={() => nextPage()} disabled={pageIndex + 1 >= Math.ceil(filteredAppointments.length / pageSize)}>Next</button>
          <button onClick={() => gotoPage(Math.ceil(filteredAppointments.length / pageSize) - 1)} disabled={pageIndex + 1 >= Math.ceil(filteredAppointments.length / pageSize)}>Last</button>
          <select
            value={pageSize}
            onChange={e => setPageSize(Number(e.target.value))}
          >
            {[5, 10, 20].map(size => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Schedule Appointment</h2>
            <form className="appointment-form">
              <label>
                Patient Name:
                <input
                  type="text"
                  name="patientName"
                  value={appointmentDetails.patientName}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Date:
                <input
                  type="date"
                  name="date"
                  value={appointmentDetails.date}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Time:
                <input
                  type="time"
                  name="time"
                  value={appointmentDetails.time}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Doctor:
                <input
                  type="text"
                  name="doctor"
                  value={appointmentDetails.doctor}
                  onChange={handleInputChange}
                />
              </label>
              <div className="modal-actions">
                <button type="button" onClick={handleSchedule}>Schedule</button>
                <button type="button" onClick={closeModal}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentDashboard;
