import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, message } from 'antd';
import apiClient from '../../../common/LoginV2/axiosClient';
import { CircularProgress } from "@mui/material";
import './AppointmentRecordManagement.css';

const AppointmentRecordManagement = () => {
  const [appointments, setAppointments] = useState([]);
  const [isShowModalVisible, setIsShowModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [patientRecords, setPatientRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [totalAppointments, setTotalAppointments] = useState(0); // To track the total number of appointments
  
  // Track form input fields
  const [clinicalObservation, setClinicalObservation] = useState('');
  const [prescription, setPrescription] = useState('');

  // Loading states for buttons
  const [isFetchingTreatmentDetails, setIsFetchingTreatmentDetails] = useState(false);
  const [isSavingRecord, setIsSavingRecord] = useState(false);

  useEffect(() => {
    fetchAppointments(); // Fetch appointments when the page loads
  }, []);

  // Fetch appointments based on doctorId
  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get('/appointment');
      setAppointments(response.data.items);
      setTotalAppointments(response.data.total);
    } catch (error) {
      message.error('Failed to fetch appointments');
    } finally {
      setLoading(false);
    }
  };

  // Fetch patient records based on appointmentId
  const fetchPatientRecords = async (appointmentId) => {
    setIsFetchingTreatmentDetails(true);
    try {
      const response = await apiClient.get(`/patientTreatmentRecord?filters=appointmentId:eq:${appointmentId}`);
      setPatientRecords(response.data.items);
    } catch (error) {
      message.error('Failed to fetch patient records');
    } finally {
      setIsFetchingTreatmentDetails(false);
    }
  };

  // Create new patient record
  const handleCreatePatientRecord = async () => {
    if (!clinicalObservation || !prescription) {
      message.error('All fields are required');
      return;
    }
    const data = {
      patientId: selectedAppointment.patientId, // Ensure the patientId is fetched from the selected appointment
      appointmentId: selectedAppointment.id,
      clinicalObservation,
      prescription,
    };
    setIsSavingRecord(true);
    try {
      const response = await apiClient.post('/patientTreatmentRecord', data);
      message.success('Patient record created successfully');
      fetchPatientRecords(selectedAppointment.id);  // Refresh the records after creating
      setIsCreateModalVisible(false);
      // Clear the form
      setClinicalObservation('');
      setPrescription('');
    } catch (error) {
      message.error('Failed to create patient record');
    } finally {
      setIsSavingRecord(false);
    }
  };

  const handleShowPatientRecords = (appointment) => {
    setSelectedAppointment(appointment);
    fetchPatientRecords(appointment.id);  // Make sure you're passing the correct property here
    setIsShowModalVisible(true);
  };

  const handleCreateRecordClick = (appointment) => {
    setSelectedAppointment(appointment);
    setIsCreateModalVisible(true);
  };

  // Handle pagination
  const handlePaginationChange = (page) => {
    setCurrentPage(page);
    fetchAppointments(); // You can add pagination to your fetch request if needed
  };

  const columns = [
    {
      title: 'Patient Name',
      dataIndex: 'patientName',
      key: 'patientName',
    },
    {
      title: 'Appointment Date',
      dataIndex: 'appointmentDate',
      key: 'appointmentDate',
    },
    {
      title: 'Appointment Time',
      dataIndex: 'appointmentTime',
      key: 'appointmentTime',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className='treatment-action-buttons'>
          <Button 
            onClick={() => handleShowPatientRecords(record)} 
            type="link"
            loading={isFetchingTreatmentDetails} // Show loader inside the button when fetching treatment details
          >
            Treatment Details
          </Button>
          <Button 
            onClick={() => handleCreateRecordClick(record)} 
            type="link"
            loading={isSavingRecord} // Show loader inside the button when saving record
          >
            Add treatment Details
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="patient-record-management-container">
      <h2>Treatment Record</h2>
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
          <p>Loading...</p>
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={appointments}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: totalAppointments,
            onChange: handlePaginationChange,
          }}
        />
      )}

      {/* Show Patient Records Modal */}
      <Modal
        title="Patient Records"
        visible={!isFetchingTreatmentDetails && isShowModalVisible}
        onCancel={() => setIsShowModalVisible(false)}
        footer={null}
      >
        <Table
          columns={[
            { title: 'Clinical Observation', dataIndex: 'clinicalObservation', key: 'clinicalObservation' },
            { title: 'Prescription', dataIndex: 'prescription', key: 'prescription' },
          ]}
          dataSource={patientRecords}
          rowKey="id"
          pagination={false}
        />
      </Modal>

      {/* Create Patient Record Modal */}
      <Modal
        title="Create Patient Record"
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
        footer={null}
      >
        <form onSubmit={(e) => { e.preventDefault(); handleCreatePatientRecord(); }}>
          <div className="form-group">
            <label>Clinical Observation</label>
            <input
              type="text"
              value={clinicalObservation}
              onChange={(e) => setClinicalObservation(e.target.value)}
              required
              placeholder="Enter clinical observation"
            />
          </div>
          <div className="form-group">
            <label>Prescription</label>
            <input
              type="text"
              value={prescription}
              onChange={(e) => setPrescription(e.target.value)}
              required
              placeholder="Enter prescription"
            />
          </div>
          <Button 
            type="primary" 
            htmlType="submit"
            loading={isSavingRecord} // Show loader inside the button when saving the record
          >
            Save Record
          </Button>
        </form>
      </Modal>
    </div>
  );
};

export default AppointmentRecordManagement;
