import React from "react";
import "./About.css";

const About = () => {
  const images = [
    require("../../images/abhisha.jpg"),
    require("../../images/chirag.jpg"),
  ];

  return (
    <section className="about" id="about-us">
      <div className="about-container">
        <h2>About Us</h2>
        <div className="about-content">
          <div className="founder-info">
            <img src={images[0]} alt="Founder 1" />
            <div className="founder-text">
              <h3>Dr. Abhilasha Maske</h3>
              <p>
                Dr. Abhilasha Maske is a graduate of the Rajiv Gandhi University
                of Health Sciences, Bangalore where she completed a 5 ½ year
                degree at SDMCNYS, Ujire. She has been a registered Doctor with
                KAUP since 2020 and is a Nutrition expert, wellness consultant
                and focuses in reversal of lifestyle disorders. She is well
                experienced in the field of trichology too . She has completed
                her B.N.Y.S, M.D Acupuncture, P.G.D.E.M.S, P.G.D.C.T and is a
                gold medalist from RGUHS University, Bangalore. She has
                successfully treated diabetes, PCOD, Thyroid conditions and many
                other lifestyle conditions as well as assisted many patients in
                the emergency care and ICU. Dr. Abhilasha has completed her
                medical training with SDMYNCH and then gained experience in
                Narayandham Naturopathy and Ayurveda Research Hospital, Pune.
                She had been with the Janseva Charitable Trust, Mumbai and St.
                George Hospital, Mumbai.
              </p>
            </div>
          </div>
          <div className="founder-info">
            <img src={images[1]} alt="Founder 2" />
            <div className="founder-text">
              <h3>Dr. Chirag M S</h3>
              <p>
                Dr. Chirag M S is a graduate of Naturopathy and Yoga from the
                Rajiv Gandhi University of Health Sciences, Bangalore. He
                completed his 5 ½ year degree at a recognized institution and
                has been a registered doctor with KAUP since 2020. Dr. Chirag is
                a holistic health expert, focusing on the power of nature and
                the body’s ability to heal itself. His approach to healing is
                comprehensive, targeting the root cause of health issues rather
                than merely addressing symptoms. He is dedicated to preventative
                medicine, encouraging his patients to embrace a healthy
                lifestyle that includes a balanced diet, regular exercise, and
                effective stress management. Dr. Chirag is skilled in various
                natural therapies such as acupuncture, herbal medicine,
                Homeopathy, Functional Medicine, and yoga, using them to treat a
                wide range of conditions including chronic pain, stress,
                fatigue, and other chronic diseases. As a yoga therapist, Dr.
                Chirag emphasizes the benefits of yoga in improving flexibility,
                balance, strength, and overall fitness. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
