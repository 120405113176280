import React from 'react';
import './Services.css';

const services = [
    { title: "Diet Plans", description: "Personalized diet plans tailored to your health needs." },
    { title: "Yoga", description: "Holistic yoga practices for physical and mental well-being." },
    { title: "Trichology", description: "Expert hair and scalp care treatments." },
    { title: "Homeopathy", description: "Natural remedies for a variety of health conditions." },
    { title: "Acupuncture", description: "Traditional acupuncture for pain relief and healing." },
    { title: "Physiotherapy", description: "Advanced therapies for physical rehabilitation." },
    { title: "Naturopathy", description: "Natural treatments for holistic health improvement." }
];

const Services = () => {
    return (
        <section className="services" id="services">
            <div className="services-container">
                <h2>Our Services</h2>
                <div className="services-content">
                    {services.map((service, index) => (
                        <div key={index} className="service-card">
                            <h3>{service.title}</h3>
                            <p>{service.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Services;
